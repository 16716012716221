import * as React from "react"
import LayoutEn from "../../components/layoutEn"

const LegalPage = () => {
  return (
    <LayoutEn pageTitle="Legal Disclaimer">
      <article className="nomal-page">
        <h1>Legal Disclaimer</h1>
        <h2 className="h2">Legal Notices and Disclosures</h2>
        <section>
          <br />
          <p>Terms of Use and Legal Information</p>
          <p>By accessing this site, you signify your agreement with and understanding of the following Terms of Use and Legal Information pertaining to both this site and any material at it. This site is offered to you conditioned on your acceptance without modification of the terms, conditions, and notices contained herein. Your use of this site constitutes your agreement to all such terms, conditions, and notices. HCM reserves the right to change the terms, conditions, and notices under which this site is offered.</p>
        </section>
        <section>
          <br/>
          <p>Ownership of Site</p>
          <p>Horiko Capital Management LLC ("HCM") owns and maintains this site. No act of downloading or otherwise copying from this site will transfer title to any software or material at this site to you. This site is for your personal and noncommercial use. You may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information, software, products, or services obtained from this site.</p>
        </section>
        <section>
          <br/>
          <p>No Offer of Securities - Disclosure of Interests</p>
          <p>Under no circumstances should any material at this site be used or considered as an offer to sell or a solicitation of any offer to buy an interest in any investment fund managed by HCM. Any such offer or solicitation will be made only by means of the Confidential Private Offering Memorandum relating to the particular fund. Access to information about the funds is limited to investors who, among other requirements, either qualify as accredited investors within the meaning of the Securities Act of 1933, as amended, or those investors who generally are sophisticated in financial matters, such that they are capable of evaluating the merits and risks of prospective investments.</p>
        </section>
        <section>
          <br/>
          <p>Disclaimer</p>
          <p>EXCEPT WHERE EXPRESSLY PROVIDED OTHERWISE, THE SITE, AND ALL CONTENT, MATERIALS, INFORMATION, AND SERVICES PROVIDED ON THE SITE, ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. HCM EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. HCM MAKES NO WARRANTY THAT: (A) THE SITE WILL MEET YOUR REQUIREMENTS; (B) THE SITE WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS; (C) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SITE OR ANY SERVICES OFFERED THROUGH THE SITE WILL BE ACCURATE OR RELIABLE; OR (D) THE QUALITY OF ANY SERVICES, INFORMATION, OR OTHER MATERIAL OBTAINED BY YOU THROUGH THE SITE WILL MEET YOUR EXPECTATIONS.</p>
          <p>ANY CONTENT, MATERIALS, INFORMATION OR OTHERWISE OBTAINED THROUGH THE USE OF THE SITE IS DONE AT YOUR OWN DISCRETION AND RISK. HCM SHALL HAVE NO RESPONSIBILITY FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY CONTENT, MATERIALS, OR INFORMATION.</p>
          <p>HCM RESERVES THE RIGHT TO MAKE CHANGES OR UPDATES TO THE SITE AT ANY TIME WITHOUT NOTICE.</p>
        </section>
        <section>
          <br/>
          <p>Limitation of Liability</p>
          <p>IN NO EVENT SHALL HCM BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES, OR DAMAGES FOR LOSS OF PROFITS, REVENUE, DATA OR USE, INCURRED BY YOU OR ANY THIRD PARTY, WHETHER IN AN ACTION IN CONTRACT OR TORT, ARISING FROM YOUR ACCESS TO, OR USE OF, THE SITE.</p>
          <p>SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.</p>
        </section>
        <section>
          <br/>
          <p>Applicable Laws</p>
          <p>All matters relating to your access to, and use of, the Site shall be governed by U.S. federal law or the laws of the State of New York.</p>
        </section>
      </article>
    </LayoutEn>
  )
}

export default LegalPage
